body {
  overflow: hidden;
  font-family: "Rubik", sans-serif !important;
}
.table {
  width: 100% !important;
}

.bill-scroll {
  position: absolute !important;
  inset: 0px !important;
  overflow: hidden !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}
.bill-scroll >div{
    margin-right: 0px !important;
    overflow: auto !important;
   
}
.bill-scroll >div::-webkit-scrollbar { width: 0em; }
.bill-right-side {
  height: 100vh;
}
.bill-header {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
}
.billheadercontainer {
  border-bottom: 1px solid #333;
}
.profile-header {
  margin-right: 20px;
}
.connection-container {
  background: #e9f8ff;
  width: 100vw;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.connection-header {
  margin-top: 9%;
}
.connection-content {
  margin-top: 4%;
  font-weight: 500;
  font-size: 18px;
}
.connection-content h3 {
  font-weight: 600;
}

.profile-header span {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.profile-header img {
  width: 20px;
}
.bill-left-side {
  position: sticky;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.items-id {
  background-color: #ececec;
  padding: 5px 10px;
  border-radius: 5px;
}

.bill-footer .amount-bill p {
  margin-top: 12px;
  color: #606161;
}

.amount-bill p span {
  color: black;
}
.bill-footer {
  position: absolute;
  width: 100%;
  max-width: 100%;
  left: 0px;
  bottom: 56px !important;
}
.bill-footer p {
  font-size: 1.2vw;
  color: #606161;
}
.bill-footer p span {
  color: black;
  font-weight: 600;
}

.qr-btn,
.cash-btn,
.card-btn,
.Reedem-btn,
.Paylater-btn,
.clear-btn {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  margin-top: 90%;
}

.qr-btn button {
  background: #343a40 !important;
  color: #fff !important;

  outline: none;
  border: #343a40 !important;
}

.clear-btn button {
  background: #343a40 !important;
  color: #fff !important;

  outline: none;
  border: #343a40 !important;
}
.cash-btn button {
  background: #343a40 !important;
  color: #fff !important;

  border: #343a40 !important;
}
.card-btn button {
  background: #343a40 !important;
  color: #fff !important;

  outline: none;
  border: #343a40 !important;
}
.Reedem-btn button {
  background: #343a40 !important;
  color: #fff !important;

  outline: none;
  border: #343a40 !important;
}
.Paylater-btn button {
  background: #343a40 !important;
  color: #fff !important;
  font-size: 500;
  outline: none;
  border: #343a40 !important;
}
.qr-btn button:hover {
  background: #606161 !important;
}
.total-input p {
  font-size: 1.6vw;
  color: #31aff5;
  font-weight: 700;
  margin-left: -41px;
}
.total-input p span {
  color: #31aff5;
  font-weight: 800;
}

.mrp-amount {
  margin-left: 28px;
}
.discount-amount {
  margin-left: -40px;
}
.tax-amount {
  text-align: center;
}
.qr-card-img {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}
.bill-right-side {
  background-color: #e9f8ff;
}
.datetime {
  justify-content: space-between;
  bottom: 0%;
}
.bill-right-side h6,
.vasy-img {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: "Roboto", sans-serif;
}
.bill-right-side h6 {
  left: 80%;
  top: 45%;
}
.vasy-img {
  left: 78%;
  top: 50%;
}
.vasyqrcodelogoimg{
  margin-top: 30%;
}
.vasy-img img{
  width: 150px;
}
.discount-input {
  width: 50px;
  height: 25px;
}
.date-text {
  color: black;
  margin-top: 6px;
}
.total-input input {
  width: 60px;
  height: 25px;
  border-radius: 4px;
  margin-left: -41px;
}

table tbody td {
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.td-text-right {
  text-align: right;
  padding: 8px 30px !important;
}
thead th:nth-child(n + 3) {
  text-align: right;
  padding: 8px 33px !important;
}
table {
  --bs-table-border-color: #aeb4b9 !important;
}

table th {
  position: sticky;
  top: 0;
  background-color: #ececec !important;
}

.bill-footer {
  background-color: #ececec;
}

.footer-dotted-line {
  border: none;
  border-bottom: 2px dashed black;
}
.connection-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
}
.connection-div input {
  border-radius: 7px;
  outline: none;
  border: none;
  text-align: center;
  width: 25%;
  padding: 8px 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.connection-div button {
  border-radius: 6px;

  font-size: 18px;
  color: white;
  padding: 10px 12px;
  border: none;
  background: black;
  margin-top: 3%;
}
.add-products {
  margin-top: 5%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-products input {
  max-width: 100%;
  padding: 5px 1px;
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  -webkit-transition: 1s;
  transition: 1s;
}
.timestamp {
  position: fixed;
  right: 23px;
  top: 8px;
}
.add-products input::placeholder {
  font-size: 15px;
  font-weight: 500;
}
.add-products input[type="text"]:hover {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}
.add-products input[type="text"]:focus {
  box-shadow: 0 0 5pt 2pt #d3d3d3;
  outline-width: 0px;
}
.add-products button {
  max-width: 100%;
  outline: none;
  border: none;
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
}
.add-products button:hover {
  box-shadow: 0 0 5pt 2pt #d3d3d3;
}
.message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dropdown {
  position: absolute !important;
  margin-top: 50px;
  margin-left: -100px;
  border-radius: 2px;
  height: 0px;
  width: 200px;
  transition-duration: 0.3s;
  transition-delay: 0.4s;
  z-index: 1;
}

.dropdown-row {
  box-shadow: 3px 3px 20px 1px #aaa;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 10px 10px;
}
.banner-img {
  height: 100vh !important;
}
.credit-difference{
    margin-left: -24px;
}
/* media quieries */

@media only screen and (max-width: 1300px) {
  .bill-footer {
    bottom: 4px;
  }
  .bill-footer p {
    /* font-size: 16px; */
    font-weight: 500;
  }
  .total-input p {
    font-weight: 700;
    margin-left: -25px;
  }
  .discount-amount {
    margin-left: -26px;
  }
  .loyalty-points {
    margin-left: -7%;
  }
  .items-id {
    vertical-align: middle;
  }
  .table {
    width: 100% !important;
  }
  .td-text-right {
    vertical-align: middle;
    padding: 0.5px 20px !important;
  }
  thead th:nth-child(n + 3) {
    text-align: right;
    padding: 8px 24px !important;
  }
  .qr-card-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .card-img {
    width: 13rem !important;
  }
  .card-title {
    font-size: 1rem !important;
  }
  .card-text {
    font-size: 1rem !important;
  }
  .profile-header span {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .bill-table {
    max-height: 398px;
  }
  .credit-difference{
    font-size: 12px;
  }

  .bill-footer p {
    font-size: 16px;
  }
  .total-input p {
    font-weight: 700;
    margin-left: -25px;
  }
  .discount-amount {
    margin-left: -26px;
  }
  .td-text-right {
    padding: 0.5px 20px !important;
  }
  thead th:nth-child(n + 3) {
    text-align: right;
    padding: 8px 24px !important;
  }
  .profile-header span {
    font-size: 12px;
  }
  
}
