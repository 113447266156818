.App {
  width: 100vw;
  height: 100vh;
  background: #fff;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  display: grid;
  place-items: center;
}
body {
  margin: 0%;
  padding: 0%;
}
.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.joinChatContainer input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #31aff5;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}
.joinChatContainer input:hover{
  border: 2px solid #17a2b8;
}
.joinChatContainer button {
  width: 208px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #343a40;
  color: #fff;
  cursor: pointer;
}

.joinChatContainer button:hover {
  background:#6c757d;
}